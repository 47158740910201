<template>
  <step-activity-layout :title="$t('module1.part2.activity.title')" :activity-finished="isGameFinished" :activity-started="isGameStarted">
    <template v-slot:intro>
      <activity-intro @start="isGameStarted = true"
        :title="$t('module1.part2.activity.intro.title')"
        :rules-content="$t('module1.part2.activity.intro.rules')"
        :rules-image="require('@/assets/module1/part2/activity-intro-illu.svg')"
      >
      </activity-intro>
    </template>
    <template v-slot:content>
      <quizz-activity v-if="!isGameFinished" :answers="answers" :questions="questions" @quizz-finished="quizzFinished" :enable-correction="true" @question-index-updated="onQuestionUpdated">
        <template v-slot:correction="{ correction }">
          <p class="correction" v-html="correction"></p>
        </template>
      </quizz-activity>
      <activity-result-block v-else @replay-activity="replayActivity" :is-custom-content="true">
        <template v-slot:content>
          <img class="result-image" :src="require('@/assets/module1/part2/activity-intro-illu.svg')">
          <app-button-layout @click="goToRessource" class="ressource-button">{{ $t('module1.part2.activity.ressourceLink') }}</app-button-layout>
        </template>
      </activity-result-block>
    </template>
  </step-activity-layout>
</template>

<script>
import StepActivityLayout from '@/components/training/StepActivityLayout'
import useActivityUtils from '@/utils/useActivityUtils'
import QuizzActivity from '@/components/training/QuizzActivity'
import ActivityResultBlock from '@/components/training/ActivityResultBlock'
import ActivityIntro from '@/components/training/ActivityIntro'
import AppButtonLayout from '@/components/buttons/AppButtonLayout.vue'

export default {
  name: 'Module1Part2Activity',
  components: { AppButtonLayout, ActivityResultBlock, QuizzActivity, StepActivityLayout, ActivityIntro },
  setup () {
    const { scoreActivity, isGameStarted, isGameFinished, replayActivity, onQuestionUpdated, onActivityFinished } = useActivityUtils()
    const goToRessource = () => window.open('https://plateforme.programme-oscar-cee.fr/autre/boite-a-outils/documents', '_blank')
    return { isGameFinished, onActivityFinished, isGameStarted, scoreActivity, replayActivity, onQuestionUpdated, goToRessource }
  },
  data () {
    return {
      questions: [
        {
          name: 'question1',
          label: this.$t('module1.part2.activity.questions.question1'),
          image: require('@/assets/module1/part2/m1p2-bg-question1.svg'),
          rightAnswer: ['mpr', 'ecoptz'],
          correction: this.$t('module1.part2.activity.corrections.correction1')
        },
        {
          name: 'question2',
          label: this.$t('module1.part2.activity.questions.question2'),
          image: require('@/assets/module1/part2/m1p2-bg-question2.svg'),
          rightAnswer: ['mpr'],
          correction: this.$t('module1.part2.activity.corrections.correction2')
        },
        {
          name: 'question3',
          label: this.$t('module1.part2.activity.questions.question3'),
          image: require('@/assets/module1/part2/m1p2-bg-question3-4.svg'),
          rightAnswer: ['cee', 'cdp', 'ecoptz'],
          correction: this.$t('module1.part2.activity.corrections.correction3')
        },
        {
          name: 'question4',
          label: this.$t('module1.part2.activity.questions.question4'),
          image: require('@/assets/module1/part2/m1p2-bg-question3-4.svg'),
          rightAnswer: ['mpr', 'cdp'],
          correction: this.$t('module1.part2.activity.corrections.correction4')
        },
        {
          name: 'question5',
          label: this.$t('module1.part2.activity.questions.question5'),
          image: require('@/assets/module1/part2/m1p2-bg-question5.svg'),
          rightAnswer: ['mpr', 'ecoptz', 'tva55'],
          correction: this.$t('module1.part2.activity.corrections.correction5')
        },
        {
          name: 'question6',
          label: this.$t('module1.part2.activity.questions.question6'),
          image: require('@/assets/module1/part2/m1p2-bg-question6.svg'),
          rightAnswer: ['mpr', 'cee', 'cdp', 'ecoptz', 'tva55'],
          correction: this.$t('module1.part2.activity.corrections.correction6')
        },
        {
          name: 'question7',
          label: this.$t('module1.part2.activity.questions.question7'),
          image: require('@/assets/module1/part2/m1p2-bg-question7.svg'),
          rightAnswer: ['mpr', 'cdp'],
          correction: this.$t('module1.part2.activity.corrections.correction7')
        },
        {
          name: 'question8',
          label: this.$t('module1.part2.activity.questions.question8'),
          image: require('@/assets/module1/part2/m1p2-bg-question8.svg'),
          rightAnswer: ['cee', 'ecoptz', 'tva55', 'mpr'],
          correction: this.$t('module1.part2.activity.corrections.correction8')
        }
      ],
      answers: [
        {
          name: 'mpr',
          image: require('@/assets/logos/aides/logo-mpr.svg?inline'),
          big: true
        },
        {
          name: 'cee',
          image: require('@/assets/logos/aides/logo-cee.svg?inline'),
          big: true
        },
        {
          name: 'cdp',
          image: require('@/assets/logos/aides/logo-cdp.svg?inline')
        },
        {
          name: 'ecoptz',
          image: require('@/assets/logos/aides/logo-ecoptz.svg?inline')
        },
        {
          name: 'tva55',
          image: require('@/assets/logos/aides/logo-tva55.svg?inline')
        }
      ]
    }
  },
  methods: {
    quizzFinished () {
      this.onActivityFinished()
    }
  }
}
</script>

<style lang="scss" scoped>
.result-image {
  width: 100%;
}
.ressource-button {
  width: 100%;
}
</style>
